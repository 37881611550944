import Vue from 'vue';

import axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';
import VueWorker from 'vue-worker';
// import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n.js'
import VueGtag from "vue-gtag";
import VueMeta from 'vue-meta';

Vue.use(VueMeta)

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GA_MEASUREMENT_ID }
});

Vue.use(VueWorker);

axios.defaults.withCredentials = true;

window.axios = axios;

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

// create Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
});

if (store.state.authToken) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.authToken;
}


axiosInstance.interceptors.request.use(config => {
  config.params = {
      channel: process.env.VUE_APP_CHANNEL,
      locale: store.state.app.locale ? store.state.app.locale.code : process.env.VUE_APP_I18N_LOCALE,
      currency: store.state.app.currency ? store.state.app.currency.code : process.env.VUE_APP_DEFAULT_CURRENCY_CODE
    }

  return config;
});

window.axiosInstance = axiosInstance;

// Configure app
store.commit('mutateAppTitle', process.env.VUE_APP_TITLE)

window.eventBus = new Vue();

const app = new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
  // mounted: () => setTimeout(function(){ document.dispatchEvent(new Event("x-app-rendered")); }, 3000000)
  mounted: () => setTimeout(function(){
    document.dispatchEvent(new Event("x-app-rendered"));
  }, 25000) // wait 25 sec. and take snapshot
}).$mount('#app');
